.static-table-container {
  @media (min-width: 600px) {
    padding: 0;
    width: 100%;
  }
}

.static-table {
  &__table-cell {
    &--word-wrap {
      word-break: break-word;
    }
    @media (max-width: 1390px) {
      padding: 6px 6px;
    }

    @media (max-width: 890px) {
      padding: 2px 2px;
    }

    @media (max-width: 795px) {
      font-size: 0.6rem;
      & p {
        font-size: inherit;
      }
    }
    @media (max-width: 536px) {
      font-size: 0.4rem;
      & p {
        font-size: inherit;
      }
    }
  }
  &__table-cell-id {
    @media (max-width: 584px) {
      display: none;
    }
  }
}
